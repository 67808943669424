const splash_element = document.querySelector("#splash-element");
const pageContent = document.querySelector("#page-content");
const pathName:any = window.location.pathname

// HERO IMAGE MOBILE INJECTION
if(window.innerWidth < 992){
    const hero = document.querySelector('#hero-content');
    const ImgHandle = document.querySelector('#hero-img-handle');
    
    ImgHandle.classList.add('mt-5', 'mb-3', 'justify-content-center');
    hero.insertBefore(ImgHandle, hero.children[0]);
}

// ANIMATIONS ON LOAD
const animations_by_page: any = {
    '/': {
        on_load_animations: [ '.nav-item' ]
    }
}
splash_element.addEventListener("animationend",AnimateOnLoad, false);

function AnimateOnLoad(){
    // Hide loading element and Display page contents
    splash_element.classList.add('visually-hidden');
    pageContent.classList.remove('visually-hidden');
    animations_by_page[pathName].on_load_animations.forEach((item: any)=>{
        // get all class occurrences, loop and set animation state
        document.querySelectorAll(item).forEach((el:any)=>{
            // Run element if element exists
            if (el != null)
            {
                el.style.animationPlayState = 'running';
            }
            else{
                console.log('[' + el + '] is not available on this page!');
            }
        })
    });
    splash_element.removeEventListener("animationend",AnimateOnLoad, false);
}


