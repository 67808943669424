// Listeners
document.querySelector('#NAME').addEventListener('change',(e:any)=> updateState('name', e.target.value), false);
document.querySelector('#EMAIL').addEventListener('change',(e:any)=> updateState('email', e.target.value), false);
document.querySelector('#MESSAGE').addEventListener('change',(e:any)=> updateState('message', e.target.value), false);
document.querySelector('#SUBJECT').addEventListener('change',(e:any)=> updateState('subject', e.target.value), false);
document.querySelector('#ST').addEventListener('change',(e:any)=> updateState('st', e.target.value), false);
document.querySelector('#ContactForm').addEventListener('submit', (e:any) => SendMail(e),false);

const states:any = {
    name: "",
    email: "",
    message: "",
    subject: "Select a topic", 
    st: undefined
}

async function SendMail(e:any) {
    e.preventDefault();

    if(states.subject === 'Select a topic'){
        alert('Subject is required, please select a subject..')
        return;
    }
    // Button logic
    let btn:any = document.querySelector('#submitBtn');
    btn.disabled = true;
    let savedBG = btn.style.backgroundColor;
    btn.style.background = '#efeeea';
    document.querySelector("#Form-Messager").innerHTML = "Sending email...";
    
    const mail = {
        name: states.name,
        email: states.email,
        message: states.message,
        subject: states.subject,
        service: 'Contact form',
        st: states.st
    }
    fetch('/mailer', {
        method: "POST",
        headers: {'Content-type': 'application/json; charset=UTF-8'},
        body: JSON.stringify(mail)
    })
    .then((res)=>{
        btn.disabled = false;
        btn.style.background = savedBG;
        if(res.status === 200){
            return res.json()
        }
    })
    .then((data)=>{
        e.target.reset();
        PurgeForm();
        document.querySelector("#Form-Messager").innerHTML = data.message;
    })
    .catch((err)=>{
        console.log(`Error: ${err.message}`);
        document.querySelector("#Form-Messager").innerHTML = 'Something went wrong, try again..';
    })

}

const updateState = (state:any, value:any) => {
    states[state] = value;
}

const PurgeForm = () => {
    states.name = "";
    states.email = "";
    states.message = "";
    states.subject = "Select a topic";
    document.querySelector("#Form-Messager").innerHTML = "";
}