let cardTrigger:Element = document.querySelector(`#card-trigger`);
let form:Element = document.querySelector(`#ContactForm`);
window.addEventListener('scroll', ScrollTrigger, false);

function is_in_view(element:Element){
    let position:DOMRect = element.getBoundingClientRect();
    if(position.top < window.innerHeight && position.bottom >= 0) 
    {
        return true;
    }
    return false;
}

function ScrollTrigger(){
    if (cardTrigger != null && is_in_view(cardTrigger)){
        import('./js/cards')
        .then((module)=>{
            module.default()
        })
        cardTrigger = null;
    }
    if(form != null && is_in_view(form)){
        require('./js/form-handler')
        form = null;
    }
    if (form === null && cardTrigger === null){
        window.removeEventListener('scroll', ScrollTrigger, false);
    }
}

// ABOUT IMAGE DESKTOP INJECTION
if(window.innerWidth >= 992){
    const about = document.querySelector('#about-content');
    const div = document.createElement('div');
    div.classList.add('column','col-lg-4');
    div.innerHTML = `
    <div id="aboutImageContainer"> 
        <img
            id='about-image'
            src="https://thehoundcdn.nyc3.cdn.digitaloceanspaces.com/jodadev-assets/img/Smaller/computer.jpg"
            alt='Computer - by FreePix'
            loading='lazy'
            width="600"
            height="600"
        />
    </div>
    `;

    about.appendChild(div);
}

